<template>
  <div v-if="Object.keys(vehicle).length > 0" class="mx-auto position-relative">
    <div class="title-vehicle py-6 position-md-sticky position-relative d-none d-md-block">
      <div class="container-xxl">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-6">
            <h2 class="fs-2 fw-bold">
              {{ vehicle.brand.brand }}  {{ vehicle.model.model }} 
              {{ vehicle.version.version }}
            </h2>
            <p class="mb-2 text-gray">
              {{ vehicle.year.year }} - {{ kmFormat(vehicle.mileage) }} km -
              {{ vehicle.model.model }} -
              {{ vehicle.transmission.transmission }}
            </p>
          </div>
          <div class="col-lg-6 d-none d-md-block">
            <h1 class="fs-xl fw-bold text-lg-end text-success pe-2">
              ${{ priceFormat(vehicle.price) }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 ">
      <div class="row mb-md-8 position-relative overflow-mobile">
          <div class="d-flex justify-content-between d-lg-none position-absolute back-to">
              <router-link to="/catalogo" class="d-flex align-items-center justify-content-center btn btn-secondary p-0 rounded-circle h-35px w-35px" >
                  <i class="fas fa-arrow-left text-white fs-3"></i>
              </router-link>
          </div>
        <Gallery v-if="images.length > 0" :images="images" :status="vehicle.status.status"/>
      </div>
      <div class="title-vehicle py-6 position-md-sticky position-relative d-block d-md-none mb-8">
        <div class="container-xxl">
          <div class="row">
            <div class="col-lg-6">
              <h2 class="fs-2 fw-bold">
                {{ vehicle.brand.brand }}  {{ vehicle.model.model }} 
                {{ vehicle.version.version }}
              </h2>
              <p class="mb-1 text-gray">
                {{ vehicle.year.year }} - {{ kmFormat(vehicle.mileage) }} km -
                {{ vehicle.model.model }} -
                {{ vehicle.transmission.transmission }}
              </p>
            </div>
            <div class="col-lg-6 d-none d-md-block">
              <h1 class="fs-1 fw-bold text-lg-end text-success">
                ${{ priceFormat(vehicle.price) }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="container-xxl">
        <div class="row ">
          <div class="col-lg-8 order-lg-1 order-2">
            <p class="d-none d-md-block fs-sm">
              <span
                ><router-link to="/catalogo" class="fs-sm text-gray"
                  >Comprar vehículos - Listado de Vehículos</router-link
                >
                -</span
              >
              {{ vehicle.brand.brand }} {{ vehicle.model.model }}
              {{ vehicle.version.version }}
            </p>
            
            <h1 class="fs-1 fw-bold">Características</h1>
            <hr />
            
            <div class="row my-7">
              <div class="col-6">
                <div v-for="spec in specs_left">
                  <p>
                    {{ spec.name }} 
                    <span v-if="spec.value == true">Si</span>
                    <span v-if="spec.value == false">No</span>
                    <span v-if="spec.value !== false && spec.value !== true">{{ spec.value }}</span>
                  </p>
                  <hr />
                </div>
              </div>
              <div class="col-6 ">
                <div v-for="spec in specs_right">
                  <p>
                    {{ spec.name }} 
                    <span v-if="spec.value == true">Si</span>
                    <span v-if="spec.value == false">No</span>
                    <span v-if="spec.value !== false && spec.value !== true">{{ spec.value }}</span>
                  </p>
                  <hr />
                </div>
              </div>
            </div>

            <h1 class="fs-1 fw-bold">Información adicional</h1>
            <hr />
            <div class="px-5">
              <p class="text-break mt-8 mb-10">
                {{ vehicle.description }}
              </p>

              <div class="row my-7">
                <div class="col-6">
                  <h4 class="fs-base fw-bold">Único dueño</h4>
                </div>
                <div class="col-6 text-end mb-3">
                  <p class="mb-0">{{ vehicle.unique_owner }}</p>
                </div>
                <hr />
                <div class="col-6">
                  <h4 class="fs-base fw-bold">Servicios en agencia</h4>
                </div>
                <div class="col-6 text-end mb-3">
                  <p class="mb-0">{{ vehicle.agency_service }}</p>
                </div>
                <hr />
                <div class="col-6">
                  <h4 class="fs-base fw-bold">Certicado</h4>
                </div>
                <div class="col-6 text-end mb-3">
                  <p class="mb-0">{{ vehicle.has_certificate }}</p>
                </div>
                <hr />
              </div>
              
            </div>
          </div>
          <div class="col-lg-3 col-xxl-4 order-lg-2 order-1 mb-3">
            <span class="fs-xs text-gray">Agencia</span>
            <div class="d-flex align-items-center mt-2">
              <div
                class="bg-gray-site rounded w-75px h-75px  text-center d-flex align-items-center justify-content-center"
              >
                <i class="text-white isax-shop fs-2hx"></i>
              </div>
              <div class="h-75px ms-3 mb-3">
                <p class="mb-1">{{ vehicle.agency.name }}</p>
                <p class="fs-sm text-gray mb-1">{{ vehicle.agency.address }}</p>
                <p class="fs-sm text-gray mb-1">{{ vehicle.agency.state }}, {{ vehicle.agency.city }}</p>
              </div>
            </div>
            
            <div class="row">
              <div class="col-12 me-auto mb-5">
                <a :href="`/#/vender-auto/${vehicle_id}`">
                  <img class="img-fluid rounded" src="media/images/Banner_auto_a_cuenta.png" alt="auto-a-cuenta" >
                </a>
              </div>
            </div>

            <div class="row">
              <router-link
                class="btn my-3 col-12 btn-primary d-none d-md-block fs-base fw-normal"
                v-if="vehicle.status.status != 'Apartado'"
                :to="'/apartar-auto/' + vehicle_id"
              >
                Apartar vehículo
              </router-link>
              <button v-else
                class="btn my-3 col-12 btn-primary d-none d-md-block fs-base fw-normal" disabled
                style="cursor:not-allowed;"
              >
                Apartar vehículo
              </button>
              <router-link  class="btn my-3 col-12 btn-secondary text-white d-none d-md-block fs-base fw-normal" v-if="vehicle.status.status == 'Disponible' && canIMeet" :to="'/agendar-cita/' + vehicle_id" >
                  Agendar cita
              </router-link>
              <button  class="btn my-3 col-12 btn-secondary text-white d-none d-md-block fs-base fw-normal" :style="!canIMeet ? 'cursor:not-allowed':''"  :disabled="vehicle.status.status != 'Disponible' && !canIMeet" v-else>
                {{ !canIMeet ? 'Sin agenda disponible' : 'Agendar cita'}} 
              </button>
              <div class="redes col-lg-1 mt-5 mt-md-0">
                <div class="d-flex flex-lg-column flex-row position-relative">
                    <a href="javascript:void(0);" @click="redirects('whatsapp')">
                      <button class="btn btn-success btn-square p-1 my-3 me-md-0">
                        <inline-svg
                          class="w-20px h-20px"
                          src="media/icons/iconsax/Whatsapp.svg"
                        />
                      </button>
                    </a>
                      <el-tooltip
                        class="d-none d-lg-block" 
                        effect="dark"
                        :content='`<span  @click="redirects(\"phone\")"> ${variable}  <i class="far fa-copy text-white"></i> </span>`'
                        raw-content
                        placement="left"
                      >
                        <el-button class="btn btn-primary d-none d-lg-block btn-square p-1 ms-0 my-2 me-4 me-lg-0" @click="redirects('phone')">                       
                        <inline-svg
                          class="w-20px h-20px"
                          src="media/icons/iconsax/call.svg"
                        /></el-button>
                      </el-tooltip>
                      <el-tooltip
                        class="d-block d-lg-none" 
                        effect="dark"
                        raw-content
                        placement="top"
                      >
                        <el-button class="btn btn-primary d-flex d-lg-none btn-square p-1 my-3 me-4 me-lg-0" @click="redirects('phone', true)">                       
                        <inline-svg
                          class="w-20px h-20px"
                          src="media/icons/iconsax/call.svg"
                        /></el-button>
                      </el-tooltip>

                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_new_card"
                      class="btn btn-danger btn-square p-1 my-3 me-4 me-md-0"
                    >
                      <inline-svg
                        class="w-20px h-20px"
                        src="media/icons/iconsax/Correo sobre-sms.svg"
                      />
                    </button>
                  </div>
              </div>

              <div class="d-block d-md-none mt-5 mb-4">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h1 class="fs-1 fw-bold text-lg-end text-success">
                      ${{ priceFormat(vehicle.price) }}
                    </h1>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-7 px-1">
                        <router-link  class="btn btn-primary px-4 py-3" v-if="vehicle.status.status == 'Disponible'"  :to="'/apartar-auto/' + vehicle_id">
                          <span class="fs-base fw-normal">Apartar</span>
                        </router-link>
                        <button class="btn btn-primary px-4 py-3" :disabled="vehicle.status.status != 'Disponible'" v-else>
                          <span class="fs-base fw-normal">Apartar</span>
                        </button>
                      </div>
                      <div class="col-5 px-1">
                        <router-link class="btn btn-secondary ms-1 px-4 py-3" v-if="vehicle.status.status == 'Disponible'" :to="'/agendar-cita/' + vehicle_id">
                          <span class="isax-calendar-1 text-white fs-2x"></span>
                        </router-link>
                        <button  class="btn btn-secondary ms-1 px-4 py-3" :disabled="vehicle.status.status != 'Disponible'" v-else>
                            <span class="isax-calendar-1 text-white fs-2x"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ref="mapDiv" class="my-4 rounded d-none d-lg-block" style="width: 100%; height: 250px"></div>
          </div>

        </div>
      </div>
      <div class="col-12 container-xxl mt-5" v-if="images_d.length > 0">
        <h1 class="fs-1 fw-bold">Desperfectos</h1>
        <hr />
      </div>
      <div class="row my-8"  v-if="images_d.length > 0">
      <Gallery :loop="false" :images="images_d" :status="vehicle.status.status" />
      </div>
    </div>
    <ContactSelletVue :idVehicle="parseInt(vehicle_id)" />
    <Carousel v-if="vehicles.length > 0" :vehicles="vehicles" title="Vehículos similares" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { onMounted, ref, watch } from "vue";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import Gallery from "./components/Gallery.vue";
import Carousel from "./components/CarouselCards.vue";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import ContactSelletVue from "./components/modals/ContactSeller.vue";
import {Loader} from  '@googlemaps/js-api-loader';
const API_KEY="AIzaSyACDXm5WBEngJZHLoEiSzzYCdlkg3THQmI";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import axios from "axios";

export default {
  name: "Detail",
  components: {
    Gallery,
    ContactSelletVue,
    Carousel
  },
  setup() {

    const variable = ref('');
    const vehicles = ref([]);
    const vehicle_id = ref("");
    const specs_left = ref([]);
    const specs_right = ref([]);

    const priceFormat = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const kmFormat = (value) => {
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const vehicle = ref({});
    const route = useRoute();
    const canIMeet = ref();

    watch(()=> route.params.id, async()=>{
      await getId(route.params.id);
      
      await getDetail(vehicle_id.value);
      vehicles.value = [];
      specs_left.value = [];
      specs_right.value = [];
      await getSpecs(vehicle_id.value);
      await getVehicles(vehicle_id.value);
    })

    onMounted(async () => {

      await getId(route.params.id);

      // vehicle_id.value = route.params.id;

      await getSpecs(vehicle_id.value);
      await getDetail(vehicle_id.value);
      await getVehicles(vehicle_id.value);
    });

    const mapDiv = ref(null);
    const loader = new Loader({apiKey: API_KEY});
    const map = ref();
    const images = ref([]);
    const images_d = ref([]);

    const getId = async (slug) => {
      try {
        const {data} = await ApiService.get('/api/vehicle/id-by-slug/'+slug);
        vehicle_id.value = data.data.id;
      } catch (error) {
        throw new Error('Error al obtener id');
      }
    };

    const getDetail = async (id) => {
      try {
        const { data } = await ApiService.get("/api/vehicle/" + id);
        vehicle.value = data.data;
        canIMeet.value = vehicle.value.cancreatemeet;
        await loader.load();
        variable.value = vehicle.value.agency.phone
        images.value = vehicle.value.images.filter(i => i.type == 'Vehiculo');
        images_d.value = vehicle.value.images.filter(i => i.type == 'Desperfecto');
        if(vehicle.value.agency.latitude && vehicle.value.agency.longitude){
          map.value  = new google.maps.Map(mapDiv.value, {
            center: {lat: parseFloat(vehicle.value.agency.latitude), lng:parseFloat(vehicle.value.agency.longitude)},
            zoom: 13
          })
          new google.maps.Marker({
            position: {lat: parseFloat(vehicle.value.agency.latitude), lng: parseFloat(vehicle.value.agency.longitude)},
            map: map.value,
            title: vehicle.value.agency.name,
          });
        }

      } catch (error) {
        console.log(error);
      }
    };
    
    const getVehicles = async (id) => {
      try {
        const {data} = await ApiService.get('/api/site/similar-vehicles-by/'+id);
        if(data.data.data.length > 0){
          vehicles.value = data.data.data;
        }
      } catch (error) {
        throw new Error('Error al obtener vehiculos');
      }
    };

    const getSpecs = async (id) => {
      try {
        await loader.load();
        const { data } = await ApiService.get("/api/site/specs/" + id);
        console.log(data);
        
        if(data.data != undefined){
          let i = 0;
          for(let key in data.data){
            if(data.data[key].value == true){
              if(i <= (data.data.length/2)){
                specs_left.value.push(data.data[key]);
              } else {
                specs_right.value.push(data.data[key]);
              }
            }
            i++;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    function copyToClipboard(text) {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        variable.value = `Copiado`
    }

    const redirects = async (type, mobile = false) => {
      // console.log("redirect", type);

      await axios.post(
        process.env.VUE_APP_API_URL + `/api/site/reg/click/${vehicle_id.value}`,
        { type:type }
      ).then((response) => {
        // console.log("response", response);

        if (response.data.success) {
          if(type == "whatsapp"){
            // console.log("Entro whatsapp");
            // window.location = vehicle.value.agency.url_whatsapp;
            window.open(vehicle.value.agency.url_whatsapp, '_blank');
          } else if (type == "phone" && mobile == false){
            // console.log("Entro phone");
            
            Swal.fire({
              icon: "success",
              position: "center",
              showConfirmButton: true,
              title: "El telefono se copio al portapapeles",
              timer:2000,
            });

            copyToClipboard(vehicle.value.agency.phone);

          } else if (type == "phone" && mobile == true){
            // console.log("Entro phone");

            window.location = `tel:${vehicle.value.agency.phone}`;

          }
        } else {
          Swal.fire({
            icon: "error",
            position: "center",
            showConfirmButton: true,
            title: "Hubo un error en el servidor", // response.data.message,
          });
        }
      }).catch((error) => {
        // console.log("error ", error.response.data);

        // if (error.response) {
        //   Swal.fire({
        //     icon: "error",
        //     position: "center",
        //     showConfirmButton: true,
        //     title: error.response.data.message,
        //   });
        // } else {
        //   console.log("error", error);
        // }
      });
    
    };


    return {
      modules: [Navigation],
      images,
      vehicle,
      priceFormat,
      kmFormat,
      vehicles,
      mapDiv,
      images_d,
      vehicle_id,
      copyToClipboard,
      variable,
      redirects,
      canIMeet,
      getSpecs,
      specs_left,
      specs_right,
    };
  },
};
</script>
<style lang="scss" scoped>
// .el-button {
//   --el-button-active-bg-color: #0860f0;
//   --el-button-hover-bg-color: #0860f0;
//   --el-button-bg-color: #0860f0;
//   --el-button-text-color: white;

// }
.title-vehicle {
  background-color: #272a31;
  top: 63px;
  z-index: 11;
  width: 100vw;
  @media screen and (max-width: 767px) {
    top: 0;
  }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    top: 50px;
  }
}
.btn-square {
  width: 50px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 50px !important;
  text-align: center;
  padding: 0px;
  font-size: 7px;
}
.redes {
  position: fixed;
  bottom: 5%;
  right: 12%;
  width: auto;
  z-index: 2;
  @media screen and (max-width: 991px) {
    position: relative;
    left: 0px
  }
  @media screen and (max-width: 1660px) and (min-width: 992px) {
    bottom: 5%;
    right: 25px;
  }
  @media screen and (max-width: 1920px) and (min-width: 1660px) {
    bottom: 5%;
    right: 30px;
  }
  @media screen and (max-width: 2500px) and (min-width: 1921px) {
    bottom: 5%;
    right: 5%;
  }
  @media screen and (max-width: 4000px) and (min-width: 2501px) {
    bottom: 5%;
    right: 5%;
  }
}
.overflow-mobile{
  overflow: hidden;
}
.back-to{
  
  @media screen and (max-width: 991px) {
      z-index: 10;left: 10%;top: 8%;
  }
  @media screen and (max-width: 500px) {
      z-index: 10;left: 5%;top: 8%;
  }
}
</style>
