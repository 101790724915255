<template>
  <div class="col-12 mx-auto my-5 container-xxl cards-gallery">
    <h1 class="ms-5">{{title}}</h1>
  <hr>
  </div>
  <div class="col-11 mx-auto overflow-visible">
  <swiper
    v-if="vehicles.length > 0"
    class="no-seleccionable cards-gallery"
    style="cursor: grab"
    :navigation="false"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: true,
    }"
    :modules="[...modules]"
    :loop="vehicles.length > 1"
    :breakpoints="swiperOptions.breakpoints"
    :space-between="20"
    :centeredSlides="true"
  >
    <SwiperSlide class="px-1" v-for="vehicle in vehicles" :key="vehicle.identificador">
      <CardVehicle :vehicle="vehicle" />
    </SwiperSlide>
  </swiper>
  </div>
</template>
<script>
  // Import Swiper Vue.js components
import 'swiper/swiper-bundle.min.css'
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import CardVehicle from '@/views/client/components/CardVehicle.vue';
import { Thumbs, Navigation, Autoplay } from "swiper";
  // Import Swiper styles
import { onMounted, ref } from 'vue';

  export default {
    components: {
      Swiper,
      SwiperSlide,
      CardVehicle
    },
    props: {
      title: String,
      vehicles: Array
    },  
    setup(props) {
      const swiperOptions = ref({
        breakpoints: {
          1300: {
            slidesPerView: 5,
            spaceBetween: 30
          },
          1025: {
            slidesPerView: 4,
            spaceBetween: 30
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
            autoplay: false
          },
        }
      })
      return {
        swiperOptions,
        modules: [Navigation, Thumbs, Autoplay],
      };
    },
  };
</script>

<style lang="scss" >

</style>