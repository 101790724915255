
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

interface contactData {
  name: string;
  last_name: string;
  email: string;
  phone: string;
}

export default defineComponent({
  name: "ContactModal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props:{
    idVehicle: Number
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newCardModalRef = ref<null | HTMLElement>(null);
    const formContact = ref<any>(null);
    const resetBtn = ref<any>(null);

    const contactData = ref<contactData>({
      name: "",
      last_name: "",
      email: "",
      phone: "",
    });

    const validationSchema = Yup.object().shape({
      name: Yup.string().required('Nombre es obligatorio').label("Nombre"),
      last_name: Yup.string().required('Los apellidos son obligatorios').label("Apellidos"),
      email: Yup.string().required('El correo es obligatorio').email('Ingresa un email valido').label("Correo"),
      phone: Yup.string().required('El Teléfono es obligatorio').label("Teléfono")
    });

    const submit = async (values, {resetForm, setFieldError}) => {
      // if(resetBtn.value){
      //   resetBtn.value.click();
      //   hideModal(newCardModalRef.value);
      //   return;
      // }
      if (!submitButtonRef.value) {
        return;
      }


      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
    try {
      const {data} = await ApiService.post('/api/contact/'+props.idVehicle, values);
      resetForm()
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;

        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
      hideModal(newCardModalRef.value);
      Swal.fire({
        html: '<p class="fw-bold text-white">Gracias por enviarnos tu información</p><p>Nos pondremos en contacto contigo</p>',
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
          content: "mt-0"
        },
      })
      return;
    } catch (error:any) {
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;

        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
      Swal.fire({
        text: (error.response.data.message) ? error.response.data.message : 'Error al enviar la información',
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      })
    }

    };

    return {
      contactData,
      validationSchema,
      submit,
      submitButtonRef,
      newCardModalRef,
      formContact,
      resetBtn
    };
  },
});
